<template>
 <span v-show="false"></span>
</template>

<script>
export default {
  props: ['title'],

  created () {
    document.title = this.title
  },

  watch: {
    title () {
      // only used when the title changes after page load
      document.title = this.title
    }
  }
}
</script>
