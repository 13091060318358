import restApi from './congressApiService'

const siteService = {}

siteService.getSiteSettings = () => {
    return restApi.get(`api/sites/get-site/`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err)
    })
}


siteService.getSiteHotels = (startDate = null, endDate = null) => {
    let url = `api/sites/get-site-hotels/`  
    if (startDate != null || endDate != null) {
      url = `api/sites/get-site-hotels/?startDate=${startDate}&endDate=${endDate}`
    }

    return restApi.get(url)
    .then((res) => {
        if (res.data)
            return res.data
        else
            throw res
    })
    .catch((err) => {
      throw new Error(err)
    })
}

siteService.getHotel = (original_id) => {
    return restApi.get(`api/sites/get-hotel/${original_id}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw err
    })
}

export default siteService