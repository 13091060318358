import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import i18n from './i18n'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: 'places'
    }
  })
app.use(i18n)
app.use(Notifications)

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

app.mount('#app')