import {createRouter, createWebHistory, VueRouter} from 'vue-router'
import store from './store/store'

// Chunking components to allow faster loading of the page

const Home = () => import('./views/Home.vue')
const List = () => import('./views/List.vue')
const HotelDetail = () => import('./views/HotelDetail.vue')
const Confirmation = () => import('./views/Confirmation.vue')
const Cancelled = () => import('./views/Cancelled.vue')
const Error = () => import('./views/Error.vue')
const Booking = () => import('./views/Booking.vue')
const PreBooking = () => import('./views/PreBooking.vue')
const NotFound = () => import('./views/404.vue')
const GenericError = () => import('./views/GenericError.vue')
const TermsAndConditions = () => import('./views/TermsAndConditions.vue')


const routes = [
    {path: '/404', name: 'notfound', component: NotFound},
    {path: '/error', name: 'error', component: GenericError},
    {path: '/', name: 'home', component: Home},
    {path: '/list', name: 'list', component: List},
    {path: '/terms', name: 'terms', component: TermsAndConditions},
    {path: '/hotel/:id', name: 'hotel-detail-page', component: HotelDetail},
    {path: '/availability/:id', name: 'availability', component: PreBooking},
    {path: '/booking/:id', name: 'booking', component: Booking, props: true},
    {path: '/booking-detail/:reference', name: 'booking-detail', component: Confirmation},
    {path: '/booking-cancelled/:reference', name: 'booking-cancelled', component: Cancelled},
    {path: '/booking-error/:reference', name: 'booking-error', component: Error},
    {path: '/:pathMatch(.*)*', redirect: '/404'}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, _from, SavedPosition) {
        if (to.hash) {
            return {selector: to.hash, behavior: 'smooth',}
        } else if (SavedPosition) {
            return SavedPosition;
        } else {
            return {top: 0, left: 0}
        }
    },
})


router.beforeEach((to, _from, next) =>{
    const routes_to_fetch = ['home', 'list', 'hotel-detail-page']
    if (routes_to_fetch.includes(to.name)){
        store.dispatch("fetchHotels")
    }
    next()
})

export default router
